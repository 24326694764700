import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import BookMeetingButton from "./BookMeeting";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "no" : "en";
    i18n.changeLanguage(newLang);
    localStorage.setItem("language", newLang);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container position-relative">
        <NavLink className="navbar-brand" to="/">
          <img
            loading="lazy"
            src={`${process.env.PUBLIC_URL}/Logo_svart.webp`}
            height="70rem"
            alt="description"
          />
        </NavLink>

        <div
          className="collapse navbar-collapse d-flex justify-content-between ps-5"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item NavBar-list-items ms-1 me-1">
              <Link to="home" smooth={true} offset={-70} duration={500}>
                {t("home")}
              </Link>
            </li>
            <li className="nav-item NavBar-list-items ms-1 me-1">
              <Link to="Services" smooth={true} offset={-70} duration={500}>
                {t("services")}
              </Link>
            </li>
            <li className="nav-item NavBar-list-items ms-1 me-1">
              <Link to="Projects" smooth={true} offset={-70} duration={500}>
                {t("projects")}
              </Link>
            </li>
            <li className="nav-item NavBar-list-items ms-1 me-1">
              <Link to="about" smooth={true} offset={-70} duration={500}>
                {t("contact_us")}
              </Link>
            </li>
            {/* <li className="nav-item NavBar-list-items ms-1 me-1">
              <a
                href="https://www.tindie.com/stores/norskdatateknikk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("store")}
              </a>
            </li> */}

            <li className="nav-item NavBar-list-items ms-1 me-1">
              <a
                href="https://ndstore.no/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("store")}
              </a>
            </li>
          </ul>
          <div className="d-flex justify-content-end">
            <button
              onClick={toggleLanguage}
              className="language-toggle-button"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              {t("switch")}
            </button>
          </div>
          <div className="d-flex justify-content-end mt-3 mt-lg-0 d-none d-lg-flex">
            <BookMeetingButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
